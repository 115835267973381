import React from "react"
import styled from 'styled-components'
import { ThemeContext } from "../tokens/theme"

import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import * as DesignSystem from "../components/Design-system"

const PhotosPage = ( props ) => {

  const data = props.data;

  return(
    <Layout>
      <SEO title="Photos" />

      <DesignSystem.H1>Photos</DesignSystem.H1>
   
        <DesignSystem.H2>
          Unsplash
        </DesignSystem.H2>

        load from unsplas here
        <img src="https://source.unsplash.com/user/pizlfilip/1600x900" />
      
    </Layout>
  );

}

export default PhotosPage;
